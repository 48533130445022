#admin-screen {
  .fa-check-circle {
    color: $green;
  }
  .fa-times-circle {
    color: $redColor;
  }
  .curl-input {
    max-width: 30px;
  }
}

@import "admin-form";
@import "admin-emulate";
