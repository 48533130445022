#client-config-header {
  position: absolute;
  left: 140px;
  bottom: 5px;
  button + button {
    margin: 0;
  }
  .dropdown-menu {
    left: auto;
    right: 0;
  }
}
