#table-updater{
  .fa-column {
    min-width: 45px;
  }
  .slim {
    .fa-undo {
      margin: 0 !important;
    }
    .fa-column {
      min-width: 25px;
    }
  }
  .fa-minus-circle {
    font-size: 14px;
  }
  .red-bg {
    .fa-minus-circle {
      color: $state-danger-text;
    }
  }
}
