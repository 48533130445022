$modalHeight: 800px;
.related-trade {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    color: $black;
    background: $poop;
    padding: 0;
    overflow: hidden;
    height: $modalHeight - 105;
  }
  .modal-content {
    height: $modalHeight;
  }
  .related-trade-panel {
    height: 40%;
    padding-bottom: 4px;
  }
  .horizontal-grid {
    height: 60%;
  }
}
