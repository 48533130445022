#live-bids, #colored-supervision {
  .fa {
    font-size: 15px;
  }
  .fa-times-circle-o {
    margin-left: 1px;
    color: $redColor;
  }
  .fa-edit {
    color: $linkColor;
  }
  .fa-thumbs-down.active {
    color: $redColor;
  }
  .fa-thumbs-up.active {
    color: $btnActiveGreen;
  }
  .fa-check.active {
    color: $blueColor;
  }
  .fa-thumbs-down, .fa-thumbs-up, .fa-check {
    &:not(.active) {
      @extend .ultra-light-effect;
    }
  }
  .sm-spinner:before {
    content: '';
  }
}

.edit-dropdown {
  @include green-border(3px);
  padding: 10px;
  margin-left: -100px;
  .input-wrapper {
    max-width: 80px;
  }
  input[type="text"] {
    max-width: 80px !important;
  }
  .error-msg {
    width: 190px;
    padding: 5px;
  }
}

.edit-price {
  @include green-border(3px);
  .popover-content {
    padding: 9px 10px;
  }
  &.bottom {
    margin-top: 5px;
  }
  &.bottom > .arrow {
    display: none;
  }
}

#live-sticky-header {
  position: absolute;
  right: 188px;
  bottom: 0;
}

.ats-server-time {
  margin-top: 3px;
}

#multi-ats-inline {
  margin-bottom: 4px;
  display: flex;
  & > div {
    display: flex;
  }
  & > div > div {
    display: flex;
    border: 2px solid $brightRed;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 15px;
    &.enable-bidding {
      border-color: $yellowColor;
    }
    &.enable-bidding.mode-enabled {
      border-color: $brightGreen;
    }
    &.border-transparent {
      border-color: transparent;
    }
    label {
      margin-bottom: 0;
    }
  }
}

.ats-stat {
  margin-left: -10px;
}

#multi-ats-dropdown {
  display: flex;
  margin-right: 10px;
  margin-bottom: 4px;
  li > * {
    display: flex;
    .fa {
      color: $green !important;
    }
  }
}

#ats-popover {
  label {
    width: 100%;
  }
  .fa {
    float: right;
    margin-top: 2px;
  }
}
