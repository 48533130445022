.dashboard-filter {
  font-size: 12px;
  form {
    label {
      width: inherit !important;
      margin: 0;

      &:first-child input {
        margin-left: 0 !important;
      }
    }
  }
  .fixed-col {
    tbody tr {
      input:not([type="checkbox"]), select {
        width: 100%;
        max-width: inherit;
      }
      & > td:nth-child(1) {
        width: 116px;
      }
      & > td:nth-child(2) {
        width: 120px;
      }
      & > td:nth-child(3) {
        @extend .pretty-text;
        width: 116px;
        max-width: 116px;
      }
    }
  }
  .group-table {
    padding-left: 5px;
    .group-col {
      width: 155px;
      float: left;
    }
    & > .group-col:nth-child(3n) {
      clear: right;
    }
  }
  .fixed-column {
    display: inline-block;
    clear: both;
    width: 100%;
    .col, .col-2 {
      line-height: 21px;
      float: left;
      display: inline-block;
    }
    .col {
      @extend .pretty-text;
      height: 21px;
      width: 105px;
      max-width: 120px;
      padding-left: 5px;
      margin-top: 5px;
      &:nth-child(odd) {
        text-align: right;
      }
      & > * {
        margin: 0;
      }
    }
    & > .col:nth-child(6n), .col-2:nth-child(3n) {
      clear: right;
    }
    // & > .col:nth-child(6n - 1), & > .col-2:nth-child(3n) > .col:first-child {
    & > .col:nth-child(6n - 5), & > .col-2:nth-child(3n - 2) > .col:first-child {
      width: 115px;
      padding-left: 0;
      // padding-left: 15px;
    }

    input:not([type="checkbox"]), select {
      width: 100%;
      max-width: inherit;
    }
  }
  .fa-square {
    font-size: 16px;
  }
}

.criteria-modal {
  .modal-dialog {
    width: 700px;
  }
}
