.db-table-wrapper {
  height: $panelChildrenHeight - $panelHeading - 10;
  // overflow-y: auto;
  // overflow-x: hidden;
  overflow: auto;
  padding: none;
  position: absolute;
  right: 5px;
  top: 5px;
  & > table:not(.no-max-width) {
    width: 200px;
    td:first-child {
      max-width: 80px;
      width: 80px
    }
  }
}

.db-chart-wrapper {
  float: left;
  .highchart-wrapper {
    padding: 0;
  }
}
