.report-modal {
  .modal-body {
    padding-top: 0;
  }
  form {
    padding-top: 5px;
    min-height: 25px;
    padding-bottom: 5px;
  }
  tr:not(:last-child) {
    border-bottom: 1px solid;
  }
  td:first-child {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }
  .modal-dialog {
    width: 800px;
  }
  font-size: 12px;
  select {
    vertical-align: middle;
    max-width: 100px;
    width: 100px;
  }
  label {
    width: 80px !important;
    margin-right: 5px;
    text-align: right;
  }
  h5 {
    line-height: 21px;
  }
  .btn-group {
    position: absolute;
    right: 0;
    top: 5px;
    &.negative-top {
      top: -5px;
    }
  }
  // button[type="submit"] {

  // }
}
