.cusip-panel {
  padding: 10px;
}

.cusip-detail-panel {
  padding: 0;
  .row {
    min-width: 260px;
  }
  table {
    tr {
      td {
        max-width: 100px;
        @include pretty-textoverflow;
      }
    }
  }
  .cusip-info-table {
    tbody {
      tr:last-child {
        border-bottom: $table-border;
      }
    }
    tr {
      td:first-child {
        width: 82px;
      }
      td:nth-child(3) {
        width: 95px;
      }
    }
  }
  .cusip-security-table {
    tr {
      td:first-child {
        $width: 103px;
        width: $width;
        max-width: $width;
        padding-left: 0 !important;
        & > * {
          width: $width - ($borderWidth * 2) - $cellPaddingWidth;
          display: inline-block;
        }
      }
      td {
        white-space: normal;
      }
    }
    .call-data-table {
      tr {
        td:first-child {
          width: 70px;
        }
      }
    }
  }
  .pricing-table {
    width: initial;
    td:not(:first-child) {
      min-width: 75px;
    }
  }
}

#cusip-detail-popup {
  #tkg-header {
    form {
      padding: 5px;
    }
  }
}

@import "trade-analysis";
@import "cusip-chart";
