$gp-list: (
  'chevron-right',
  'chevron-left',
  'th-list',
  'fast-forward',
  'forward',
  'fast-backward',
  'backward'
);

$fa-list: (
  'thumbs-down',
  'thumbs-up',
  'plus',
  'check',
  'times-circle-o',
  'file-text-o',
  'cloud-download',
  'bar-chart',
  'cloud-upload',
  'caret-down',
  'chevron-right',
  'chevron-left',
  'th-list',
  'fast-forward',
  'forward',
  'fast-backward',
  'backward',
  'pause',
  'play',
  'refresh',
  'edit',
  'copy',
  'minus-circle',
  'undo',
  'check-circle',
  'times-circle',
  'arrows-h',
  'chevron-up',
  'chevron-down',
  'toggle-on',
  'toggle-off',
  'cog',
  'pencil-square-o',
  'times',
  'close',
  'filter',
  'arrow-down',
  'arrow-up',
  'ban',
  'file-pdf-o',
  'long-arrow-up',
  'camera',
  'spinner',
  'warning'
);

.ie {
  @mixin fa($size) {
    width: $size;
    height: $size;
    background-size: $size;
    vertical-align: middle;
  }
  .fa, .glyphicon {
    width: 16px;
    height: 16px;
    &:before {
      content: '';
    }
  }

  .fa-14 {
    & > .fa {
      @include fa(14px);
    }
  }

  .fa-12 {
    & > .fa {
      @include fa(12px);
    }
  }
  .fa-h-16 {
    height: 16px !important;
  }
  .fa-mt-0 {
    .fa {
      margin-top: 0 !important;
    }
  }
  @each $item in $fa-list {
    .fa-#{$item} {
      background: url($fa-black + '/' + $item + '.png');
      &.white-text {
        background: url($fa-white + '/' + $item + '.png');
      }
    }
  }
  @each $item in $gp-list {
    .glyphicon-#{$item} {
      background: url($fa-black + '/' + $item + '.png');
      &.white-text {
        background: url($fa-white + '/' + $item + '.png');
      }
    }
  }

  .fa-square {
    width: 13px;
    height: 13px;
    border-radius: 2px;
  }

  .fa-toggle-on {
    height: 13px;
  }
  .fa-toggle-off {
    height: 13px;
  }
  .modal-header {
    .fa-times, .fa-close {
      background: url($fa-white + '/times.png');
    }
    .fa-camera {
      background: url($fa-white + '/camera.png');
    }
  }
  .fa-circle {
    width: 11px;
    height: 11px;
    border-radius: 11px;
  }

  .fa-arrow-down, .fa-arrow-up, .fa-filter {
    width: 14px;
    height: 14px;
    background-size: 14px;
  }

  .s-th {
    .fa-arrow-down, .fa-arrow-up {
      margin-right: 2px;
    }
  }

  .glyphicon-fast-forward, .fa-fast-forward,
  .glyphicon-forward, .fa-forward,
  .glyphicon-fast-backward, .fa-fast-backward,
  .glyphicon-backward, .fa-backward {
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin-top: -3px !important;
    background-size: 14px;
  }

  .btn-default.btn {
    .fa, .glyphicon {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .fa-caret-down {
      background: url($fa-white + '/caret-down.png');
    }
    .glyphicon-chevron-right, .fa-chevron-right {
      background: url($fa-white + '/chevron-right.png');
    }
    .glyphicon-chevron-left, .fa-chevron-left {
      background: url($fa-white + '/chevron-left.png');
    }
    .fa-filter {
      background: url($fa-white + '/filter.png');
    }
  }

  .input-group-addon {
    .glyphicon, .fa {
      width: 16px !important;
    }
    .glyphicon-th-list, .fa-th-list {
      margin-top: 1px;
    }
  }
}
