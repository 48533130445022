.d-flex, .flex {
  display: flex !important;
}

.d-flex-not-important {
  display: flex;
}

.ml-auto {
  margin-left: auto;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.d-flex-center {
  display: flex !important;
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.mx-2 {
  margin-left: 5px;
  margin-right: 5px;
}

.justified-content-center {
  justify-content: center;
}

.gap-10 {
  gap: 10px;
}

.flex-basis-60 {
  flex-basis: 60%;
}
