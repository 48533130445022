#counterparty {
  .cass-id {
    width: 120px;
  }

  .archive-col {
    width: 25px;
  }

}

.counterparty-form {
  max-width: inherit;
}
