.market-depth-table {
  table > tbody > tr:last-child td {
    border-bottom: 0;
  }
}

.strategy-dropdown {
  max-height: 500px;
  padding: 0;
  overflow: hidden !important;
  ul {
    padding-bottom: 5px;
  }
  li span, li label {
    white-space: nowrap;
  }
}
