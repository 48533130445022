#sentinel-trade {
  table {
    select {
      width: 100%;
    }
    .date-select {
      max-width: 100px !important;
    }
    .form-group {
      width: 100%;
    }
  }
  .max-130 {
    max-width: 138px;
  }
  .tkg-pagination {
    width: 220px;
    display: inline-block;
  }
  .badge-dropdown {
    margin-right: 10px;
    padding: 2px 10px;
  }
  #count-dropdown-menu {
    width: 950px;
    left: -111px;
    li {
      button {
        width: 100%;
      }
      strong {
        display: inline-block;
        text-align: right;
        width: 100px;
      }
      & > table {
        td {
          padding-left: 0px !important;
          .badge.right {
            float: right !important;
            margin-top: 3px;
          }
        }
      }
    }
    // li {
    //   strong {
    //     display: inline-block;
    //     text-align: right;
    //     width: 100px;
    //   }
    //   button {
    //     width: 150px;
    //   }
    // }
  }
}


@import "./related-trade";
@import "./report";
