.cusip-info-table {
  tbody {
    tr:last-child {
      border-bottom: $table-border;
    }
  }
  tr {
    td:first-child {
      width: 82px;
    }
    td:nth-child(3) {
      width: 95px;
    }
  }
}

.cusip-security-table {
  tr {
    td:first-child {
      width: 82px;
    }
  }
}
