$fa-font-path: "./../../node_modules/font-awesome/fonts";
$navbar-inverse-link-color: #5AADBB;
$icon-font-path: "./../../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
$fa-black: "./../../node_modules/font-awesome-svg-png/black/png/16";
$fa-white: "./../../node_modules/font-awesome-svg-png/white/png/16";
$fa-black-22: "./../../node_modules/font-awesome-svg-png/black/png/22";
$fa-white-22: "./../../node_modules/font-awesome-svg-png/white/png/22";
@import "./../../node_modules/angular-loading-bar/src/loading-bar.css";
@import "./../../node_modules/angular-toastr/dist/angular-toastr.css";
@import "./../../node_modules/font-awesome/scss/font-awesome";
@import "./../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}

@import "./../../src-common-wp/styles/font-awesome-img/font-awesome-img";
@import "./../../src-common-wp/styles/variables";
@import "./modules/dashboard/variables";
@import "./modules/variables";
@import "./../../src-common-wp/styles/common-modules";
@import "./../../src-ranger/src/styles/modules/ranger-modules";
@import "./../../src-scout/src/styles/modules/_modules.scss";
// @import "../../src-dashboard/src/styles/modules/db-modules";
// @import "../../src-sector/src/styles/modules/sector-modules";
@import './modules/g18-modules';
