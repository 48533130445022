.modal-content {
  td:nth-child(even) {
    label {
      width: 50%;
      min-width: 0;
      float: left;
      input[type="radio"] {
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
}

.add-trade {
  $textWidth: 7vw;
  $maxWidth: 100px;
  .modal-content {
    width: 100% !important;

    input[type="text"] {
      min-width: $textWidth;
      width: $textWidth;
      max-width: $maxWidth;
    }
    td:not(.td-label) {
      min-width: $textWidth + 1;
      width: $textWidth + 1;
      max-width: $maxWidth + 10;
    }
    td.td-price, td.td-price > * {
      min-width: 60px;
      max-width: 65px;
    }
    td.td-side {
      min-width: 105px;
      max-width: 105px;
      label:nth-child(1) {
        margin-left: 5px;
      }
    }

    @media screen and (min-width: 1300px) {
      $textWidth: 100px;
      input[type="text"] {
        min-width: $textWidth;
        width: $textWidth;
        // max-width: $maxWidth;
      }
      td:not(.td-label) {
        min-width: $textWidth + 10;
        width: $textWidth + 10;
        // max-width: $maxWidth + 10;
      }
    }
  }
  .modal-dialog {
    width: 70% !important;
    min-width: 700px;
    max-width: 800px;
  }
}

.ie {
  .modal-header {
    vertical-align: middle;
    & > .pull-right {
      height: 35px;
      line-height: 35px;
      vertical-align: middle;
    }
  }
}
