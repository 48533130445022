#trade-analysis {
  padding: 0;
  font-size: 12px;
  padding-top: 0;
  & > section {
    border-bottom: 3px solid #e5e5e5;
    padding-bottom: 5px;
  }
  .row {
    min-width: 260px;

    .exceptionName {
      text-align: right;
      width: 60px;
      float: left;
    }
    .exceptionValue {
      width: 200px;
    }
  }

  table {
    tr {
      td {
        max-width: 100px;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        @include pretty-textoverflow;
        // &:first-child:not(.strong) {
        //   font-weight: 600;
        // }
      }
    }
    tbody > tr:first-child {
      td {
        padding-top: 2px !important;
      }
    }
  }

  h5 {
    display: inline-block;
    font-weight: bold;
    padding-left: 5px;
  }
}
.check-result-table {
  tr {
    td {
      text-align: left;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
.check-result-tooltip {
  .tooltip-inner {
    max-width: initial;
  }
}
