.strategy-dropdown {
  button[uib-dropdown-toggle] {
    width: 105px;
  }
  .hint-list {
    min-width: inherit;
    min-width: 105px;
  }
}

.source-dropdown {
  margin-left: -200px;
  max-height: 500px;
  overflow: hidden;
  .overflow-y {
    max-height: 450px;
  }
}
