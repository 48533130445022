#analysis-sticky-head {
  position: absolute;
  right: 10px;
  float: right;
  margin-top: 1px;
}
#trade-analysis {
  // padding: 10px;
  font-size: 12px;
  padding-top: 0;
  .row {
    min-width: 260px;

    .exceptionName {
      text-align: right;
      width: 60px;
      float: left;
    }
    .exceptionValue {
      width: 200px;
    }
  }

  .check-result {
    &:after {
      content: "";
      height: 0px;
      clear: both;
      display: block;
    }
  }

  table {
    tr {
      td {
        max-width: 100px;
        @include pretty-textoverflow;
      }
    }
  }

  .col-1 {
    width: 57px;
    float: left;
    font-weight: bold;
    text-align: right;
  }
  .exception-body {
    padding-left: 62px;
    * {
      padding: 0;
    }
  }

  .check {
    clear: both;
  }

  @media screen and (min-width: 1800px) {
    .col-xs-6 {
      width: 33%;
    }
  }

  @media screen and (max-width: 1080px) {
    .col-xs-6 {
      width: 100%;
    }
  }
}
