.config-list-column {
  max-height: 100%;
  overflow: auto;
}

.strategy-list {
  li {
    @extend .group;
    margin-bottom: 5px;
    i {
      line-height: 21px;
    }
  }
}

.scout-config-modal {
  // max-height: 835px;
  overflow: hidden;
  .modal-dialog {
    margin-top: 1em;
    height: calc(100% - 2em);
  }
  .modal-body {
    height: calc(100% - 100px);
  }
  .uib-timepicker {
    input {
      max-width: 45px !important;
      width: 25px !important;
      border-color: $white !important;
      background: $white !important;
      border-radius: 4px !important;
      color: black !important;
    }
    .uib-separator {
      padding-right: 3px !important;
      padding-left: 3px !important;
    }
  }
}

#configForm {
  .tooltip-max {
    max-width: 200px;
    width: 100%;
    left: 15px !important;
  }
  .btn.btn-default.active {
    background-color: #28ca28;
  }
  hr {
    width: 100%;
    float: left;
  }
  & > .input-label, & > label {
    height: 45px;
    white-space: nowrap;
    margin-bottom: 15px;
  }
  [uib-dropdown] {
    button[uib-dropdown-toggle] {
      // width: 100px;
      white-space: nowrap;
      text-align: left;
      span {
        @include pretty-textoverflow;
        // width: 75px;
        width: 80%;
        display: inline-block;
        line-height: 16px;
      }
    }
  }
  ul.dropdown-menu {
    max-height: 300px;
    overflow: auto;
    input[type="text"] {
      width: 80px !important;
    }
    li > label {
      width: 100%;
      padding: 0 10px;
      cursor: pointer;
      input[type="checkbox"] {
        width: initial !important;
      }
    }
  }
  .input-label {
    .form-name {
      @include pretty-textoverflow;
      // width: 100px;
      width: 90%;
      display: inline-block;
    }
    & > label {
      width: 100%;
    }
  }

  .date-time {
    display: flex;
    align-items: center;
    .uib-timepicker {
      display: block;
      margin: 0;
    }
    td {
      input {
        &:focus {
          border-color: $poop;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $poop;
        }
        border: {
          left: 0;
          right: 0;
          radius: 0;
          color: $poop;

        }
        background: transparent;
        color: $white;
      }
      .glyphicon {
        color: $originalPoop;
      }
    }
  }

  .grid-param table label {
    margin-bottom: 0;
  }
  input {
    text-align: center !important;
  }
  input:not([type="radio"]):not([type="checkbox"]), button.dropdown-toggle {
    width: 100%;
    max-width: 200px;
  }

  .colspan-2 {
    input:not([type="radio"]):not([type="checkbox"]), button.dropdown-toggle {
      width: 100%;
      max-width: initial;
    }
    [uib-dropdown] {
      button[uib-dropdown-toggle] {
        span {
          width: 95%;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    input:not([type="radio"]):not([type="checkbox"]), button.dropdown-toggle {
      max-width: 175px;
    }
  }

  @media screen and (max-width: $screen-sm) {
    input:not([type="radio"]):not([type="checkbox"]), button.dropdown-toggle {
      max-width: 120px;
    }
  }

  @for $i from 1 through 12 {
    .col-md-#{$i}, .col-sm-#{$i}, .col-xs-#{$i} {
      &:not(.with-padding) {
        padding-right: 0;
      }
    }
  }

  .separator {
    width: 100% !important;
    clear: both;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .fa-circle {
    width: 12px;
    height: 12px;
    width: 40%;
  }
  ngjs-color-picker {
    ul {
      margin: 0;
    }
  }
}

.sortable-list {
  li {
    height: 40px;
    a {
      position: absolute;
      width: 70%;
      left: 50px;
    }
    .pull-right {
      .fa {
        font-size: 22px;
      }
      .fa:not(:last-child) {
        margin-right: 5px;
      }
    }

  }
}

.cusip-config {
  display: flex;
  min-height: 25px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid white;
  border-radius: 4px;
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  tr > td:nth-child(2) {
    width: 100px;
  }
  tbody > tr:first-child td {
    padding-top: 4px !important;
  }
  input.form-control {
    margin: 0;
    max-width: 90px !important;
  }
  .dropdown-toggle {
    width: 95px !important;
  }
  thead th, thead td{
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: $green;
    border-bottom: 1px solid white;
  }
  .fa-ban {
    color: $brightRed;
  }
  .fa-check-circle {
    color: $greenSuccess;
  }
}
