#tkg-header {
  input[type="text"] {
    width: 100px;
  }
  input[type="text"].price-field {
    width: $responsiveInputMaxWidth/2 + 20px;
  }

  // .dropdown-menu {
  //   input[type="text"] {
  //     width: inherit !important;
  //   }
  // }
  .upload-dropdown {
    margin-top: 5px;
    width: 300px;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    li {
      & > a {
        height: 20px;
      }
    }
    li:first-child {
      & > input[type="text"] {
        width: 33% !important;
      }
      & > select {
        width: 30% !important;
      }
    }
  }
  form[name="pretrade"] {
    padding-bottom: 8px;
  }
}

.g18-app-dropdown.dropdown-menu[uib-dropdown-menu] {
  min-width: 103px;
  border-color: $white;
  font-size: 12px;

  & > li > a {
    padding: 0px 10px;
    color: $white;

    .beta-tag {
      background-color: #009b03;
      color: white;
      padding: 2px 5px;
      border-radius: 5px;
      font-size: 0.9em!important;
      margin: 0 5px;
    }

    .retired-tag {
      background-color: #a2a2a2;
      color: white;
      padding: 2px 5px;
      border-radius: 5px;
      font-size: 0.9em!important;
      margin: 0 5px;
    }

    &:hover, &:focus {
      background: $btnHoverGreen;

      .beta-tag {
        background-color: #4CC44D;
      }

      .retired-tag {
        background-color: #a2a2a2;
      }

    }
    &:active {
      background: btnActiveGreen;
    }
  }
}

.uploaded-file {
  & > a, & > label {
    position: relative;
    &:before {
      content: ' ';
      background: transparent;
      width: 20px;
      position: absolute;
      height: 20px;
      left: 5px;
      z-index: 2;
    }
    & > input[type="checkbox"] {
      width: 12px !important;
      cursor: pointer;
      position: relative;
    }
    & > * {
      padding: 0;
      &:nth-child(2) {
        padding: 0 5px;
        font-size: 11px !important;
        font-style: italic;
        @include pretty-textoverflow;
      }
      &:nth-child(3) {
        &:before {
          content: "-";
          padding-right: 5px;
        }
      }
      // &:nth-child(1) {
      //   padding-left:
      // }
    }
  }
}

#sticky-header-bottom-2 {
  right: 175px;
  &.none {
    right: 10px;
  }
}

.sentinel-header-dropdown {
  min-width: 130px;
}

.password-dropdown {
  position: fixed;
  margin: 0 !important;
  right: 5px;
  top: 25px !important;
  left: initial;
}

.modal-password {
  .modal-dialog {
    right: 5px;
    position: absolute;
    width: 188px;
  }
}

#tkg-header {
  .fa-camera {
    position: absolute;
    right: 3px;
    bottom: 8px;
    & + sentinel-header {
      #sticky-header-bottom {
        right: 40px;
      }
    }
  }
}

sentinel-header + .fa-camera {
  position: absolute;
  right: 152px;
  bottom: 8px;
}

@include hideBanner(900px);
@import "config-header";
