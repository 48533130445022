#offering {
  .file-name-list {
    @include pretty-textoverflow;
    width: 50%;
    display: inline-block;
    // margin-top: 3px;
    text-align: right;
    position: relative;
  }

  .loading {
    .glyphicon {
      margin-right: 5px;
      font-size: 14px;
      line-height: 12px;
      height: 16px;
      vertical-align: middle;
    }
  }

  .progress-wrapper {
    @extend .file-name-list;
    margin-top: -1px;
    margin-bottom: 0;
    float: right;
    text-align: left;
    .loading {
      // margin-top: 3px;
    }
    .progress {
      // margin-top: -2px;
    }
  }

  .panel-title {
    position: relative;
    .tooltip-inner {
      max-width: inherit;
    }
    .tooltip {
      left: 0 !important;
    }
  }

  .offering-list-heading {
    .panel-title {
      // margin-top: -2px;
    }
  }
}
