.curve-inputs {
  position: relative;
  .absolute-input > .form-group {
    position: absolute;
  }
  .fa-chevron-up, .fa-chevron-down {
    position: absolute;
    left: 45%;
  }
  .fa-chevron-up {
    top: -15px;
  }
  .fa-chevron-down {
    top: 25px;
    margin: 0;
  }
  .form-row {
    height: 22px;
    margin-left: 0;
    margin-right: 0;
  }
  margin-top: 20px;
}

.curve-body {
  height: 260px;
  .highcharts-container {
    border-radius: 4px;
  }
}

.curve-highchart {
  background-color: white;
  border-radius: 4px;
}

.curve-wrapper {
  margin-bottom: 25px !important;
  min-height: 280px;
}

.curve-select {
  // height: 210px;
  .highcharts-container {
    border-radius: 4px;
  }
}

.curve-select-wrapper {
  min-height: 205px;
  .curve-select-header {
    min-height: 31px;
  }
}

#configForm .curve-select-wrapper [uib-dropdown] button[uib-dropdown-toggle] span {
  width: 100%;
}

