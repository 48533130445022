.panel-heading {
  $textWidth: 7vw;
  form {
    input[type="text"], select {
      width: 7vw;
      max-width: 90px;
    }
  }
  form.unrestrict {
    input[type="text"], select {
      width: initial;
      max-width: 200px;
      // width: 7vw;
      // max-width: 90px;
    }
  }
  .tkg-pagination {
    // margin-left: 0;
  }
}

// .panel-body {
//   position: initial;
// }
