#admin-emulate {
  padding-right: 5px;
  padding-left: 5px;
  select {
    width: 100px;
  }
  .btn-poop {
    padding: 0 5px !important;
  }
}
