$modalCol1: 120px;
$modalCol2: 120px;
$modalCol5: 75px;
$modalWidth: 900px;
$headerHeight: 60px;
$addOnTemplate: show-history;
$addOnTemplateHeight: 40%;
// $green: $equityGreen;
// $poop: $equityPoop;
$btnGreen: $inactiveGreen;
$btnHoverGreen: lighten($btnGreen, 8%);
$btnActiveGreen: lighten($btnGreen, 12%);
