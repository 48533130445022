#sector {
  table {
    input[type="text"] {
      width: 100%;
      min-width: 30px;
      max-width: 100px;
      text-align: right;
    }
    input[type="radio"] {
      margin-top: 0;
    }
    span {
      text-align: right;
      display: block;
      width: 100%;
    }
  }
  .messaging {
    .alert {
      padding: 0 10px;
      margin-bottom: 0;
    }
  }
  .treasory-label {
    width: 220px;
    text-align: right;
  }
}

.sector-group {
  @extend .col-xs-2;
  width: 130px;
  float: left;
  padding: 0;
}


.sector-table-wrapper {
  @extend .col-xs-10;
  min-width: 1200px;
  float: right;
}

.muni-table {
  .col-xs-12 {
    min-width: 1200px;
  }
  .col-xs-6, .col-sm-6 {
    min-width: 550px;
  }
  table {
    min-width: 525px;
  }
}

@media screen and (max-width: $screen-sm) {
  .sector-table-wrapper {
    min-width: 0;
  }
  .muni-table {
    .col-xs-12 {
      min-width: 0;
    }
  }
}
